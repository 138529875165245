import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const SecondPage = () => (
  <Layout>
    <SEO title="Animation" description="List of all my animations" />
    <div className="row">
      <div className="block-content">
        <div className="animations">
          <div className="animation">
            <h3>Omusubi Kororin</h3>
            <p>
              I have created this animation for the 3rd year of my Foundation Degree. In this animation, I've continued
              to explore traditional textiles techniques and incorporating them with digital animation. I have
              experimented different layering techniques with the weaving to create a background with more depth.
            </p>
            <div className="responsive-embed widescreen">
              <iframe
                title="Omusubi Kororin"
                src="https://www.youtube.com/embed/F9CtNdLS8mk"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
          <div className="animation">
            <h3>The Tale of The Chinese Zodiac</h3>
            <p>
              I have created this animation for the 2nd year of my Foundation Degree. Once again I have tried to explore
              different 2D traditional techniques not often used in animation. I have used weaving to create the
              backgrounds and the characters were done on Animate CC.
            </p>
            <div className="responsive-embed widescreen">
              <iframe
                title="The Tale of The Chinese Zodiac"
                src="https://www.youtube.com/embed/CTpVtTIF78U"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
          <div className="animation">
            <h3>The Old Man Who Made Flowers Bloom</h3>
            <p>
              This is an animation that I&apos;ve done for the first year of my Foundation Degree in Art and Design.
              This animation uses a variety of techniques: digital painting, collage and print. The Old Man Who Made
              Flowers Bloom is a fairytale from Japan.
            </p>
            <div className="responsive-embed widescreen">
              <iframe
                title="The Old Man Who Made Flowers Bloom"
                src="https://www.youtube.com/embed/uz48x8qL4LM"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
          <div className="animation">
            <h3>Caterpillar</h3>
            <p>
              The original idea was just to move the caterpillar around but one thing led to the other and I ended up
              doing more insects. This is also my first time animating insects so I'm open to constructive feedback.
            </p>
            <div className="responsive-embed widescreen">
              <iframe
                title="Caterpillar"
                src="https://www.youtube.com/embed/NYeOtxLvQMU"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default SecondPage;
